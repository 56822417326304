.card {
    border-radius: 10px;
    overflow: hidden;
    width: 300px; 
    text-align: center;
    transition: transform 0.2s ease-in-out;
    position: relative;
  
    &:hover {
      transform: translateY(-10px);
    }
  
    .card-image {
        margin-top: 20px;
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .card-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;  
        align-items: center;  
        text-align: center;
        color: white;  

        .prompt-box {
          top: 53%;
          position: absolute;
          font-size: large;
          color: black;
          font-weight: 700;
        }

        .answer-box {
          top: 65%;
          position: absolute;
          color: black;
          font-weight: 700;
          font-size: large;

        }

        .rarity-box {
          flex-direction: column;
          gap: 10px;
          display: flex;
          top: 20%;
          right: 13%;
          position: absolute;
          color: rgb(251, 255, 0);
          font-weight: 700;
          font-size: xx-large;
        }

        .icon-container {
          position: absolute;
          top: 20%;        
          justify-content: center;
          align-items: center;

          img {
            width: 130px;
            height: 130px;;
          }
      }
  
  
    @media (max-width: 768px) {
      .icon-container{
        top: 16%;
      }

      .answer-box {
        top: 58%;
      }

      .prompt-box {
        top: 45%;
      }
    }
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

}