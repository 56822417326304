.home-page {
    .text-area {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    } 

    h1 {
        color: rgb(0, 0, 0);
        font-size: 52px;
        margin: 0;
        font-family: 'Popins'; // Want a blocky text
        font-weight: 600;
     

        img {
            width: 40px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #000000;
        margin-top: 20px;
        font-weight: 400;
        font-size: 14px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color : #800000;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #800000;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 2.2s backwards;
        white-space: nowrap;

        &:hover {
            background: #800000;
            color: white;
        }
    }
}

@media screen and (max-width: 768px) {
    .home-page {
        .gameboy {
            display: none;
        }
        .text-area {
            // border: 1px solid #07cf89;
            position: absolute;
            max-height: 90%;
            width: 90%;
            bottom: 20%;

            h1 {
                // border: 1px solid #07cf89;
                line-height: 40px;
                font-size: 38px;
            }

        }
    }

    
}

