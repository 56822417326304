
.container.secret-page {
  h1 {
    left: 0;
  }
  justify-content: center;
  .canvas-area {
    top: 20%;
    left: 10%;
    position: absolute;
    display: flex;
    width: 40%;
    height: 60%;
    // border: 1px solid #026efc; // testing

    button.openBox {
      border: 4px solid #02fc83; // testing
    }
  }


  .card-area {
    justify-content: center;
    right: 10%;
    top: 20%;
    display: flex;
    background: black;
    // border: 1px solid #0249fc; // testing
    position: absolute;
    width: 40%;
    height: 60%;
  }

  .right-btn {
    background: none;
    border: none;
    position: absolute;
    right: 7%;
    top: 40%;

    img {
      width: 80px;
      height: 80px;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .left-btn {
    background: none;
    border: none;
    position: absolute;
    left: 7%;
    top: 40%;
    img {
      width: 80px;
      height: 80px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .options {
    display: flex;
    width: 100%;
    background: black;
    justify-content: center;

    
    
    ul {
      display: grid;
      text-align: center;
      list-style: none;
      padding: 0;

      li {
        padding: 10px;
        color: white;

        button {
          font-weight: bold;
          width: 100%;
          height: 100%;
          border: 1px solid #fc0255;
          background: black;
          color: white;
          padding: 20px;
          cursor: pointer;
          transition: 0.5s;
          border-radius: 5px;

          &:hover {
            background: #fc0255;
            color: black;
        }
      }
     
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container.secret-page {
    overflow-y: auto;
    h1 {
      text-align: center;
      position: static !important;
      float: left;
      transform: none !important;
      margin-top: 130px;
    }
    .canvas-area {
      top: 40%;
      width: 80%;
      height: 100%;
      flex-direction: column;
    }
  
    .options {
      flex-direction: column;
    }
  
    .card-area { 
      display: flex;
      position: absolute;
      top: 140%;
      width: 80%;

      .left-btn {
        left: 7%;
        top: 83%;
      }
      .right-btn {
        right: 7%;
        top: 83%;
      }
    }
  }

}
