
.container {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .text-area {
        flex: 1;
        padding: 20px;
    }

    .right-display {
        flex: 2;
        position: absolute;
        top: 5%;
        width: 45%;
        height: 90%;
        left: 50%;
    
        h2 {
            font-style: italic;
            color: #FFD700;
            font-size: 3em;
            margin-bottom: 20px;

        }
    
    
        ul {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            display: grid;
            gap: 20px;
            list-style-type: none;
            padding: 0;
            margin: 0;
            gap: 10px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: gold;
                border-radius: 4px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background-color: #ff0000;
            }
            
    
            li {
                flex-direction: column;

                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                align-items: center;
                justify-content: center;
                padding: 20px;
                border-radius: 10px;
    
                background: #800000;
                color: #FFD700;
                border: 1px solid #FFD700; // testing
      
                i {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;
                    font-size: 3em;
                    color: #FFD700;
                    display: flex;
                    align-items: flex-start;
                    background: #800000;
                    padding: 5px;
                    border-radius: 8px;
    
    
                    span {
                        font-size: 24px;
                        font-weight: 400;
                        margin-top: 5px;
                        font-family: Georgia, 'Times New Roman', Times, serif;
                        padding-right: 5px ;
                        margin-left: 10px;
                        color: #FFD700;
                    }
    
                    &:hover {
                        animation: expandIconLeft 0.5s;
                        background: #FFD700;
                        color: #800000;
    
                        span {
                            color: #800000;
                        }
                    }
                }
    
            }
        }
    } 
}

.game-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    text-align: center;

    .project {
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;

        color: #FFD700;
        // border: 1px solid #FFD700;  



        h3 {
            margin: 0;
            padding-right: 20px;
            font-size: 2em;
            font-weight: bold;
            margin-bottom: 10px;
        }

        h4 {
            margin: 0;
            font-size: .55em;
            font-weight: bold;
            margin-bottom: 10px;
        }

        p{
            margin-right: 10px;
            color: #FFD700;
            font-size: 1.5em;
            font-weight: bold;
            margin-bottom: 10px;
        }

        img {
            width: 200px; 
            height: auto; 

            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
    }
}


@media screen and (max-width: 768px) {
    .container {
        overflow-y: auto;
        .text-area {
            position: static !important;
            float: left;
            transform: none !important;
            margin-top: 170px;
        }

        .gameboy-img {
            h1 {
                margin-left: 60px;
                color: #FFD700;
                top: 30%;
                position: absolute;
                font-size: 2em;
            }
            img {
                top: 10%;
                left: 50%;
                position: absolute;
                rotate: -15deg;
                width: 45%;
                height: auto;
            }
        }

        .right-display {
            position: absolute;
            top: 60%;
            width: 85%;
            left: 7%;

            .project {
                flex-direction: column;
                text-align: center;
                
                p {
                    margin-bottom: 20px;
                }
                
                h3 {
                    font-size: 2em;
                    margin-bottom: 0;
                    h4 {
                        font-size: .8em;
                        margin-bottom: 0;
                    }
                }

                img {
                    width: 150px;
                    height: auto;
                }
            }
        }
    }
    

        
}
