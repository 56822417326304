$primary-color: #e1ec1a;

@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';

@font-face { // Main font
  font-family: 'Popins';
  src: url('./assets/Fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face { // Secondary font
  font-family: 'Open Sans';
  src: url('./assets/Fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face { // Tertiary font
  font-family: 'Roboto';
  src: url('./assets/Fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face { // Quaternary font
  font-family: 'Pixel';
  src: url('./assets/Fonts/PressStart2P-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}












