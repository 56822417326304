.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s; 
}

.container.secret-page,
.container.contact-page,
.container.portfolio-page,
.container.quick-potfolio-page,
.container.about-page {
  // border: 1px solid #e1e4e8; // testing
  .text-area {
      // border: 1px solid #e1e4e8; // testing

      position: absolute;
      left: 10%;
      transform: translateY(-50%);
      top: 50%;
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;

      h2 {
          font-size: 1.5em;
          position: relative;
          left: 10px;
          color : #fff;
          animation: pulse 1s;
          &:nth-of-type(1) {
            animation-delay: 1.1s;
        }
      }

      h3 {
          font-size: 1.3em;
          position: relative;
          left: 10px;
          color : #fff;
          animation: pulse 1s;
          &:nth-of-type(1) {
              animation-delay: 1.4s;
          }
      }
  }

  h1 {
      font-size: 54px;
      font-weight: 400;
      font-family: 'Lucida Sans';
      color: #fff;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;
  }

  p {
      font-size: 14px;
      color: #fff;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }

}

@media screen and (max-width: 768px) {
  .page {
    height: auto;
    position: relative;
  }

  .container {
    width: 100%;
    height: auto;
    min-height: 900px;
    max-height: 1200px;
    padding-bottom: 58px;
    box-sizing: border-box;
    position: relative;
    top: 0;
    overflow: hidden;
  }



  
}
