@import url('https://fonts.googleapis.com/css?family=Poppins:900i');


.buttonX-wrapper {
  display: flex;
  justify-content: center;
}

.buttonX {
    display: flex;
    padding: 10px 45px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    color: var(--button-text-color, white);
    background: var(--button-bg-color, #6225E6);
    transition: 1s;
    box-shadow: 6px 6px 0 var(--button-complement-color, #FBC638);
    transform: skewX(-15deg);
}

.buttonX:focus {
   outline: none; 
}

.buttonX:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 var(--button-complement-color, #FBC638);
}

.buttonX span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
}

.buttonX:hover span:nth-child(2) {
    transition: 0.5s;
    margin-right: 45px;
}

.buttonX span {
    transform: skewX(15deg);
}

.buttonX span:nth-child(2) {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
}

/* SVG Animations */
path.one {
    transition: 0.4s;
    transform: translateX(-60%);
}

path.two {
    transition: 0.5s;
    transform: translateX(-30%);
}

.buttonX:hover path.three {
    animation: color_anim 1s infinite 0.2s;
}

.buttonX:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

.buttonX:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}

@keyframes color_anim {
    0% {
        fill: white;
    }
    50% {
        fill: var(--button-complement-color, #FBC638);
    }
    100% {
        fill: white;
    }
}