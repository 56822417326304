.container.about-page {

    .headshot {
        right: 58%;
        top: 30px;
        border: 1px solid #e1e4e8;
        overflow: hidden;
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 50%;
        position: absolute;
    }

    .left-side-about {
        right: 0;
        margin-right: 30px;
        position: absolute;
        display: inline-block;
        width: 50%;
        height: 80%;
        top: 10%;
        // border: 1px solid #e0e0e0;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        scrollbar-color: #ee3030 #f5f5f5;
    
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
        &::-webkit-scrollbar-track {
            background: #f5f5f5;
        }
    

        .vertical-timeline-element {
            p {
                color: black;
            }
        }
    
        .vertical-timeline-element-icon {
            
            img {
                background: #ee3030;
                object-fit: contain;
                border-radius: 50%;
            }

        }

        .vertical-timeline-element-content {
            border: 2px solid #FFD700;

        }

        .date {
            font-weight: bold;
            color: black;
            font-size: 20px;
            margin-right: 20px;
            margin-left: 20px;
        }   

    
    }
    .portfolio-button {
        display: inline-block;
        margin-top: 10px;
        padding: 10px 20px;
        background-color: #B22222;
        color: white;
        text-decoration: none;
        border-radius: 5px;
        text-align: center;


        &:hover {
            background-color: #ee3030;
        }
    }
}

@media screen and (max-width: 768px) {
    .container.about-page {
        overflow-y: auto;
        .headshot {
            left: 60%;
            top: 70px;
            width: 150px;
            height: 150px;
        }
        .text-area {
            position: static !important;
            max-height: auto;
            min-height: 350px;
            float: left;
            transform: none !important;
            margin-top: 170px;
        }
        .left-side-about {
            top: 90%;
            left: 0;
            width: 100%;
        }
    }
}


