.nav-bar {
    background: black;
    width: 60px;
    height: 100%;
    position: absolute;
    z-index: 3;
    top : 0;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px, 0; 

        img {
            display: block;
            margin: 8px auto;
            width: 24px;
            height: auto;

            &.sub-logo {
                width: 50px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;
        

        a {
            font-size: 22px;
            color : #4d4d4d;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            &.active {
                svg {
                    color: #dc143c;
                }
            }

            

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {                
                svg {
                    color: #dc143c;
                }
            }

            &:after {
                content : '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            } 
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;// top bottom
                display: block;
                font-size: 15px;
                line-height: 16px;
                color: #4d4d4d;

                &:hover svg{
                    color: #dc143c;
                }
            }
        }

    }
}

.hamburger-icon,
.close-icon {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
    .nav-bar {
        background: black;
        width: 100%;
        height: 60px;
        position: fixed;
        z-index: 3;
        top : 0;
        left: 0;
        min-height: 0;

        .logo > img:first-child {
            height: 30px;
        }

        .logo {
            float: left;
            padding: 2px 0;
            margin-right: 20px;
    
            img {
                display: block;
                margin: 5px 15px 5px 19px;
                width: auto;

                &.sub-logo {
                    width: 50px;
                    margin-left: 5px;
                }
            }
        }

        .hamburger-icon {
            position: absolute;
            color : #fff;
            float: right;
            margin: 15px 30px 10px 10px;
            display: inline-flex;
            font-size: 30px;
            
            &.active {
                color:#dc143c;
            }
        }

        nav {
            width: 100%;
            position: fixed;
            left: 100%;
            opacity: 0;
            margin-left: 0;
            float: left;
            overflow: hidden;
            text-align: center;
            height: 60px;
            top: 60px;
            margin-top: 0;
            background: #181818;
            -webkit-transition: all .3s ease-out;
            transition: all .3s ease-out;

    
            a {
                float: left;
                margin-left: 0 !important;
                line-height: 60px;
                height: 60px;
                width: 16.5%;
            }



            &.show-bar {
                left: 0%;
                opacity: 1;
            }
        }
    
        ul {
            position: static;
            bottom: 20px;
            width: auto;
            height: 60px;
            line-height: 60px;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
            float: left;
    
            li {
                float: right;
                height: 60px;
                line-height: 60px;
                margin: -8px 15px;

                a {
                    font-size: 20px;
                    line-height: 60px;

                }
            }
    
        }
    }


}